import { TextFieldProps } from "model/object/fields";
import React from "react";
import MaskedInput from "react-text-mask";

type InputProps = TextFieldProps;

const VRMField = ({
	value,
	name,
	handleChange,
	className,
	required,
	disabled,
	autocomplete,
	placeholder = "",
	readonly = false,
	onFocus,
	onBlur,
	onKeyPress,
	tabIndex,
}: InputProps) => {
	return (
		<MaskedInput
			className={`registration-plate !font-mono font-extrabold  tracking-normal ${className}`}
			name={name}
			mask={[/^[A-Za-z0-9]*$/, /^[A-Za-z0-9]*$/, /^[A-Za-z0-9]*$/, /^[A-Za-z0-9]*$/, /^[A-Za-z0-9]*$/, /^[A-Za-z0-9]*$/, /^[A-Za-z0-9]*$/]}
			value={value}
			onChange={handleChange}
			required={required}
			disabled={disabled}
			autoComplete="off"
			readOnly={readonly}
			placeholderChar={"\u2000"}
			placeholder={placeholder}
			onFocus={onFocus}
			onBlur={onBlur}
			onKeyPress={onKeyPress}
			autoFocus
			tabIndex={tabIndex}
		/>
	);
};

export default VRMField;
