import * as yup from "yup";

export const regSchema = yup.object().shape({
	reg: yup.string().required("Please enter your registration"),
});

export const nicknameSchema = yup.object().shape({
	nickname: yup.string().required("Please enter a nickname"),
});

export const equityCalcSchema = yup.object().shape({
	typeOfFinance: yup.string().required("Please select the type of finance"),
	mileage: yup.number().integer().min(1, "Current mileage must be at least 1").required("Please enter your current mileage"),
	monthlyPayment: yup.number().integer().min(1, "Monthly payment must be at least 1").required("Please enter your current monthly payment"),
	settlementFigure: yup.number().integer().min(1, "Settlement figure must be at least 1").required("Please enter your current settlement figure"),
});
