import { useEffect } from "react";

import { Formik } from "formik";
import { setStorageValue } from "helpers";
import { GetServerSidePropsContext } from "next";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { Form } from "react-bootstrap";
import { BsFileLockFill } from "react-icons/bs";
import { FaBriefcase } from "react-icons/fa";

import { PrimaryCta } from "components/Buttons";
import VRMField from "components/Fields/VRMField";
import Loading from "components/Loading";
import { initialRef, listedRefs } from "constants/refs";
import { getUserFromServer } from "lib/auth/auth";
import { RefQuery } from "model/object/ref-query";
import { DefaultProps } from "model/props/auth";
import { regSchema } from "model/schema/carscore";

const Home = (props: DefaultProps) => {
	const router = useRouter();
	const { ref } = router.query as RefQuery;

	useEffect(() => {
		const inputElement = document.querySelector("input");
		let refData = initialRef;
		if (inputElement) {
			inputElement.focus();
		}

		if (listedRefs.includes(ref)) {
			refData = ref;
		}

		setStorageValue("user_web_source", refData);
	}, [ref]);

	if (props.auth) {
		return <Loading />;
	}

	return (
		<>
			<div className="bg-indigo px-32 pb-40 pt-20 mobileL:!pt-10 tablet:pt-10 laptop:px-10">
				<div className="px-44  text-center tablet:!px-0 laptop:px-10">
					<p className="mb-3 text-xl opacity-75">Step 1 of 2</p>

					<h2 className="mb-4 text-5xl tablet:!text-4xl">Let&apos;s find your car</h2>
					<p className="text-xl">Enter your registration number below</p>
				</div>
			</div>

			<div className="-mt-[5.5rem] mb-20 ml-auto mr-auto w-[55rem] rounded-2xl bg-white px-32 pb-10 pt-10 tablet:!w-[95vw] tablet:!px-2 laptop:w-[35rem]">
				<div className="left-0 right-0 ml-auto mr-auto flex justify-center">
					<Formik
						validationSchema={regSchema}
						onSubmit={values => router.push(`/user?vrm=${values.reg.toUpperCase().split("–").join("").split(" ").join("").replace("–", "")}`)}
						initialValues={{ reg: "" }}
					>
						{({ handleSubmit, values, handleChange, isSubmitting }) => (
							<Form onSubmit={handleSubmit} noValidate>
								<div className="align-items-center flex flex-col gap-4">
									<VRMField
										className="registration-plate left-0 right-0 ml-auto mr-auto w-[50rem] overflow-hidden rounded-3xl border-0 bg-yellow px-12 text-[10rem] text-black placeholder:text-black placeholder:text-opacity-50 focus:border-0 focus:bg-yellow focus:outline-0 tablet:!w-[18rem] tablet:!rounded-xl tablet:!px-6 tablet:text-center tablet:!text-[3rem] laptop:w-[30rem] laptop:text-[5rem]"
										name="reg"
										value={values.reg}
										handleChange={handleChange}
										placeholder=""
										required
										type="text"
										onBlur={({ target }) => target.focus()}
										tabIndex={0}
									/>
									<div className="-mt-12 !h-[2.8rem] !rounded-[10rem] bg-white tablet:!mt-0 tablet:!h-0 laptop:-m-10 laptop:!h-[3rem]">
										<PrimaryCta
											type="button"
											text="Let's go"
											hasArrowRight
											arrowRightClassName="!text-[1.5rem] !font-extrabold laptop:!text-[1rem]"
											onClick={handleSubmit}
											disabled={isSubmitting || values.reg.length < 5 || !values.reg.trim()}
											isSubmitting={isSubmitting}
											className="!h-[4rem] !px-14 !text-[1.5rem] opacity-100 outline outline-[10px] tablet:!text-xl laptop:!h-[3rem] laptop:!px-8 laptop:!text-[1rem] laptop:outline-[4px]"
										/>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				</div>
				<div className="ml-auto mr-auto flex flex-col px-10 tablet:pt-2 laptop:px-0">
					<div className="mt-12 text-center text-lg text-black">
						Or&nbsp;
						<Link href="/login/">
							<span className="fw-bold cursor-pointer !text-blue hover:underline">log in</span>
						</Link>
					</div>
					<div className="mb-3 ml-auto mr-auto mt-2 flex gap-3 pt-6 text-xl !text-[#696969] mobileL:flex-col mobileL:items-center">
						<div className="align-items-center flex gap-1 text-xl">
							<BsFileLockFill />
							<p className="text-lg tablet:text-sm">Data secured by SSL</p>
						</div>
						<div className="align-items-center flex gap-1">
							<FaBriefcase />
							<p className="text-lg tablet:text-sm">Made by car industry experts</p>
						</div>
					</div>
					<div className="align-items-center ml-auto mr-auto flex w-fit rounded-3xl bg-gray p-2 px-4 !text-black">
						<div className=" mr-1 mt-1 w-[6rem]">
							<Image src={require("../public/img/splash/stars.svg")} alt="stars" />
						</div>
						<div>
							<p>
								Rated <span className="fw-bold">4.7/5</span> by 2000+ happy UK car owners
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export const getServerSideProps = async (context: GetServerSidePropsContext) => getUserFromServer(context);

export default Home;
