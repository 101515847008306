import Image from "next/image";

const Loading = ({ title, title2, subtitle, subtitle2, className = "" }: { title?: string; title2?: string; subtitle?: string; subtitle2?: string; className?: string }) => {
	return (
		<div className={`mb-[30vh] text-center ${className}`}>
			{title && <h1 className="loadingText mt-5">{title}</h1>}

			<div className={`d-flex justify-content-center -mb-[5rem] mt-[5rem] ${className}`}>
				<div
					style={{
						position: "relative",
						height: "300px",
						width: "600px",
					}}
				>
					<Image src={require("../../public/loading.gif")} alt="loading" />
				</div>
			</div>
			{title2 && <h1 className="mt-5 text-indigo opacity-95">{title2}</h1>}
			{subtitle2 && <h2 className="mt-1 font-medium text-indigo">{subtitle2}</h2>}
			{subtitle && <h1 className="mt-5 text-indigo">{subtitle}</h1>}
		</div>
	);
};

export default Loading;
